// import $ from "jquery";
import "./custom-vendor/slick-carousel/slick/slick";

export function BuildHeroSliders() {
  $(".hero__slider-wrapper").slick({
    arrows: true,
    appendArrows: $(".hero__slider-arrows"),
    prevArrow: $(".hero__slider-prev-arrow"),
    nextArrow: $(".hero__slider-next-arrow")
  });

  var fixHeroHeights = function() {
    let tallestHero = 0;
    $(".slick-track .hero").each(function() {
      if ($(this).height() > tallestHero) {
        tallestHero = $(this).height();
      }
    });
    $(".slick-track .hero").each(function() {
      $(this).height(tallestHero);
    });
  };

  fixHeroHeights();
  $(window).on("resize", function() {
    fixHeroHeights();
  });
}
