// import $ from "../node_modules/jquery";
import { DynamicBanner } from "./thread/DynamicBanner";
import { BuildHeroSliders } from "./HeroSlider";
import { BuildGoalsListings } from "./GoalsListing";
// check if current page is Experience Editor
const isPageEditor = function() {
  return !!(Sitecore && Sitecore.PageModes && Sitecore.PageModes.PageEditor);
};

// Using this instead of document.ready to play nicely with Storybook
// patch in velirDOMContentLoadedFix.js detects DOMContentLoaded
// jQuery is doing something else
$(document).on("DOMContentLoaded", () => {
  BuildHeroSliders();
  BuildGoalsListings();

  // objectFitPolyfill();
  // $(window).resize(() => {
  //   objectFitPolyfill();
  // });

  // Dynamic Banners (Hero and Page Banner)
  const $banners = $(".js-dynamic-banner");
  if ($banners.length) {
    const dynamicBanners = $.makeArray($banners).map(el => {
      return new DynamicBanner($(el));
    });
  }
});
