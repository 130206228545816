export function BuildGoalsListings() {
  const initGoalsListing = function (listing) {
    $(listing)
      .find(".goals-listing__list-item")
      // This is intentionally NOT an arrow function
      // Need to encapsulate the `this` context
      .each((ind, elm) => {
        $(elm).on("click", function () {
          makeListingActive(this, listing);
        });

        if (ind === 0) {
          makeListingActive(elm, listing);
        }
      });
  };

  const makeListingActive = function (item, parentListing) {
    // if ($(window).width() > 600) {
    let $listItem = $(item);

    // stash this so we can clear all active classes without screwing up the check
    let listItemIsActive = $listItem.hasClass(
      "goals-listing__list-item--active"
    );

    // clear all existing active items
    $(parentListing)
      .find(".goals-listing__list-item")
      .removeClass("goals-listing__list-item--active");

    // Only update active item if clicked item isn't already active
    if (listItemIsActive == false) {
      // make clicked item active
      $listItem.addClass("goals-listing__list-item--active");

      let $parentWrapper = $listItem.closest(".goals-listing__listing-wrapper");

      let $featuredImg = $parentWrapper.find(".goals-listing__featured-image");
      let $featuredCaption = $parentWrapper.find(
        ".goals-listing__featured-image-caption"
      );

      let $replacementImg = $listItem.find(".goals-listing__list-item-image");
      let $replacementCaption = $listItem
        .find(".goals-listing__list-item-image-caption")
        .text();

      // adding class = 300ms fade out
      $parentWrapper.addClass("goals-listing__image-is-hidden");
      // wait 300ms for fade-out to end, then remove class to fade in
      setTimeout(() => {
        $featuredImg.attr("src", $replacementImg.attr("src"));
        $featuredCaption.text($replacementCaption);
        $parentWrapper.removeClass("goals-listing__image-is-hidden");
      }, 300);
      // }
    }
  };

  $(".goals-listing__listing-wrapper").each((ind, elm) => {
    initGoalsListing(elm);
  });
}
